import styled from 'styled-components';

export default styled.div`
  background-color: ${props => props.backgroundColor};
  position: relative;
  padding: 96px 0;
  @media (max-width: 768px) {
    padding: 64px 0;
  }

  .container {
    max-width: 644px;

    .section-heading,
    .section-subheading {
      position: relative;
      z-index: 99;

      @media (max-width: 768px) {
        margin-left: 10%;
        margin-right: 10%;
      }
    }

    .row {
      display: flex;
      justify-content: center;
      text-align: center;

      h2 {
        margin-bottom: 24px;
      }

      .subhead {
        margin-bottom: 72px;
      }
    }

    .list-icon {
      width: max-content;
      img {
        height: 56px;
        width: 56px;
      }
    }

    .list-heading {
      font-size: 22.5px;
    }

    .list-subhead {
      font-size: 18px;
      color: #6F7283;
    }
  }

  .right-poly {
    position: absolute;
    right: 0;
    top: -30px;
    width: 200px;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .left-poly {
    position: absolute;
    left: 0;
    top: 0;
    width: 200px;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
